<template>
  <div class="archive-wrapper">
    <h1 class="text--dark text-bold" style="margin-bottom: 20px">Inlägg</h1>
    <div class="archive-search" style="display: inline;">
      <svg class="search-icon" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M2.83419 9.08905C1.03369 6.90926 1.14941 3.66853 3.18135 1.62511C5.336 -0.541705 8.82937 -0.541705 10.984 1.62511C13.1387 3.79193 13.1387 7.30504 10.984 9.47186C8.97201 11.4952 5.79268 11.6292 3.62611 9.87372L0.948901 12.5661C0.731827 12.7844 0.37988 12.7844 0.162806 12.5661C-0.0542685 12.3478 -0.0542687 11.9938 0.162806 11.7755L2.83419 9.08905ZM3.47721 8.4424L4.2703 9.2259C6.07925 10.6287 8.68603 10.4962 10.3443 8.82855C12.1457 7.01702 12.1457 4.07995 10.3443 2.26842C8.54297 0.456891 5.6224 0.456891 3.82104 2.26842C2.1427 3.95624 2.02809 6.62113 3.47721 8.4424Z"/>
      </svg>
      <input @keyup="updateQuery" v-model="searchQuery" type="text" placeholder="Sök" class="archive-search-input">
    </div>

    <div class="" style="margin: 20px 0;"></div>

    <post-archive v-if="!searchQuery && $store.state.queryResults.length > 0 && page === 1" :large="true" :post="$store.state.queryResults[0]" style="margin-bottom: 50px"/>
    <div v-if="searchQuery || $store.state.queryResults.length > 1" class="archive-grid">
      <div v-for="post in postsToDisplay" :key="post.slug">
        <post-archive :large="false" :post="post" />
      </div>
    </div>
    <el-pagination
      style="margin: 50px auto"
      background
      hide-on-single-page
      layout="prev, pager, next"
      @current-change="scrollToTop"
      :current-page.sync="page"
      :page-size="maxPerPage"
      :total="$store.state.queryResults.length">
    </el-pagination>
  </div>
</template>

<script>
import PostArchive from '../components/PostArchive.vue'
import { debounce } from '../store/debouncer'

export default {
  metaInfo: {
    title: 'Inlägg',
  },
  components: { PostArchive },
  data() {
    return {
      searchQuery: '',
      maxPerPage: 20,
      page: 0
    }
  },
  methods: {
    updateQuery() {
      const that = this
      debounce(function () {
        that.$store.commit('setArchiveQuery', { query: that.searchQuery, order: false })
      }, 500)()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$store.commit('setArchiveQuery', { query: '', order: false })
  },
  computed: {
    postsToDisplay() {
      const all = this.$store.state.queryResults
      return all.slice(this.searchQuery ? 0 : 1).slice((this.page - 1) * this.maxPerPage, (this.page - 1) * this.maxPerPage + this.maxPerPage)
    }
  }
}
</script>

<style scoped>

.archive-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-bottom: 80px;
  margin-left: var(--horizontal-margin);
  margin-right: var(--horizontal-margin);
}

.archive-search {
  position: relative;
}

.archive-search-input {
  width: 50%;
  padding-left: 30px;
}

.archive-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  /* margin-top: 50px; */
}

.search-icon {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  fill: var(--c-text-dark);
  content: 'dw';
}

@media screen and (max-width: 1200px) { 
  .archive-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));

  }
}

@media screen and (max-width: 800px) {
  .archive-search-input {
    width: 100%;
  }
  .archive-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

</style>